<template>
  <!-- eslint-disable -->
  <div class="flex-lg-row-fluid ms-lg-10" v-if="app">

    <h2 class="text-white mb-7">{{ app.name }}</h2>

    <div v-if="deletion_stage < 1">
      <ul class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
        <li class="nav-item">
          <a @click="page = 'main'" :class="{active: page=='main'}" class="nav-link text-active-primary pb-4">Ustawienia aplikacji</a>
        </li>
        <li class="nav-item">
          <a @click="page = 'stats'" :class="{active: page=='stats'}" class="nav-link text-active-primary pb-4">Statystyki i limity</a>
        </li>
      </ul>


      <div v-if="page == 'main'">
        <div class="card mb-5 mb-xl-10">
          <div class="card-header cursor-pointer">
            <div class="card-title m-0">
              <h3 class="fw-bolder m-0">Szczegóły aplikacji</h3>
              <span v-if="app.status == 'OK'" class="badge badge-success badge-copy">OK</span>
              <span v-else-if="app.status == 'Nieaktywna'" class="badge badge-secondary badge-copy">{{ app.status }}</span>
              <span v-else class="badge badge-warning text-dark badge-copy">{{ app.status }}</span>
            </div>
            <a v-if="edit.basics == false" @click="startEditionBasics" class="btn btn-primary align-self-center">Edytuj</a>
          </div>
          <div v-if="edit.basics == false" class="card-body p-9">
            <div class="row mb-7">
              <label class="col-lg-4 fw-bold text-muted">Nazwa aplikacji</label>
              <div class="col-lg-8">
                <span class="fw-bolder fs-6 text-dark">{{ app.name }}</span>
              </div>
            </div>
            <div class="row mb-7">
              <label class="col-lg-4 fw-bold text-muted">Opis</label>
              <div class="col-lg-8 d-flex align-items-center">
                <span class="text-justify fs-6">{{ app.description }}</span>
              </div>
            </div>
            <div v-if="app.internal_client" class="row mb-7">
              <label class="col-lg-4 fw-bold text-muted">Rodzaj aplikacji</label>
              <div class="col-lg-8">
                <span v-if="app.sandbox_only" class="fs-6 text-dark">Aplikacja testowa (tylko sandbox)</span>
                <span v-else class="fs-6 text-dark">Aplikacja produkcyjna</span>
              </div>
            </div>
          </div>
          <div v-else class="collapse show">
            <div class="card-body border-top p-9">
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Nazwa aplikacji</label>
                <div class="col-lg-8 fv-row">
                  <input type="text" :class="{ 'is-invalid': errors.name }" @change="errors.name = false" class="form-control form-control-lg form-control-solid" v-model="app.name" />
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.name }}</div>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Opis</label>
                <div class="col-lg-8 fv-row">
                  <textarea rows="6" :class="{ 'is-invalid': errors.description }" @change="errors.description = false" class="form-control form-control-lg form-control-solid" v-model="app.description" />
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.description }}</div>
                </div>
              </div>
              <div v-if="app.internal_client" class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Rodzaj aplikacji</label>
                <div class="col-lg-8 fv-row">
                  <select v-model="app.sandbox_only" class="form-select form-select-solid form-select-lg">
                    <option :value="false">Aplikacja produkcyjna (dostęp live + sandbox)</option>
                    <option :value="true">Aplikacja testowa (tylko sandbox)</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button @click="edit.basics = false; reload()" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
              <button @click="saveBasics" class="btn btn-primary">Zapisz zmiany</button>
            </div>
          </div>
        </div>

        <div class="card mb-5 mb-xl-10">
          <!--begin::Card header-->
          <div class="card-header border-0 cursor-pointer" role="button">
            <div class="card-title m-0">
              <h3 class="fw-bolder m-0">Dane dostępowe</h3>
            </div>
            <label class="form-check form-switch form-check-custom form-check-solid">
              <div v-if="!app.active" class="ms-auto">
                <button @click="resetKey" class="mx-10 btn btn-secondary btn-light btn-sm" v-bind:class="{ 'btn-danger': resetOver }" @mouseover="resetOver=true" @mouseleave="resetOver=false">Resetuj AppKey</button>
              </div>
    					<input @click="toggleActivity" class="form-check-input w-30px h-20px" type="checkbox" :checked="app.active" :disabled="!adminMode && app.active_admin == false">
    					<span v-if="app.active" class="form-check-label text-muted fs-6">Aplikacja aktywna</span>
    					<span v-else class="form-check-label text-muted fs-6">Aplikacja nieaktywna</span>
    				</label>
          </div>
          <!--end::Card header-->
          <!--begin::Content-->
          <div class="collapse show">
            <div class="card-body border-top p-9">
              <div class="d-flex flex-wrap align-items-center">
                <div>
                  <div class="fs-6 fw-bolder mb-1">App Id</div>
                  <div class="fw-bold text-gray-600">{{ app.id }}</div>
                </div>
                <div class="ms-auto">
                  <button @click="copyAppId" class="btn btn-secondary btn-sm">Kopiuj</button>
                </div>
              </div>
              <div class="separator separator-dashed my-6"></div>
              <div class="d-flex flex-wrap align-items-center">
                <div>
                  <div class="fs-6 fw-bolder mb-1">App Key</div>
                  <div v-show="showAppKey" class="text-gray-600">{{ app.key }}</div>
                </div>
                <div class="ms-auto">
                  <button v-if="showAppKey == false" @click="showAppKey=true" class="btn btn-light btn-secondary btn-sm">Pokaż App Key</button>
                  <button v-else @click="copyAppKey" class="btn btn-secondary btn-sm">Kopiuj</button>
                </div>
              </div>
              <div class="separator separator-dashed my-6"></div>
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Content-->
        </div>

        <div class="row g-lg-9">
          <div class="col-lg-6">
            <div class="card card-lg-stretch mb-5 mb-lg-10">
              <div class="card-header border-0 cursor-pointer" role="button">
                <div class="card-title m-0">
                  <h3 class="fw-bolder m-0">Uprawnienia</h3>
                </div>
                <button type="button" @click="startEditionScopes" class="btn btn-primary align-self-center" data-bs-toggle="modal" data-bs-target="#modalScopes">Edytuj</button>
              </div>
              <div class="card-body border-top pt-3 fs-6">
                <ul class="scopes-list">
                  <li v-for="scope in app.scopes">{{ scope.description }}</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="card card-lg-stretch mb-5 mb-lg-10">
              <div class="card-header border-0 cursor-pointer" role="button">
                <div class="card-title m-0">
                  <h3 class="fw-bolder m-0">Adresy IP</h3>
                </div>
                <button v-if="app.ip_restrictions" @click="edit.ips_mode = 'edit'" type="button" class="btn btn-primary align-self-center" data-bs-toggle="modal" data-bs-target="#modalIps" data-bs-whatever="@modalIps">Edytuj</button>
              </div>
              <div class="card-body border-top pt-3">
                <table v-if="app.ip_restrictions" class="table table-row-bordered table-row-dashed gy-5 fs-6">
                  <tbody>
                    <tr v-for="app_ip in app.ips">
                      <td class="p-0 pt-3 pb-3" v-if="!app_ip.only_sandbox || app.internal_client">
                        <div>
                          {{ app_ip.ip }} <span v-if="app_ip.only_sandbox"> - tylko sandbox</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td><a @click="edit.ips_mode = 'add'" class="link-primary fs-5 me-1 cursor-pointer" data-bs-toggle="modal" data-bs-target="#modalIps" data-bs-whatever="@modalIps">Dodaj IP</a></td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="mt-3 fs-6">Zgodnie z decyzją administratora, adresy IP dla tej aplikacji nie są sprawdzane.</div>
              </div>
              <div v-if="adminMode" class="card-footer border-0">
                <label class="form-check form-switch form-check-custom form-check-solid">
                  <input @click="toggleIpRestrictions" class="form-check-input w-30px h-20px" type="checkbox" :checked="app.ip_restrictions">
                  <span v-if="app.ip_restrictions" class="form-check-label text-muted fs-6">Wymagaj zgodności adresów IP</span>
                  <span v-else class="form-check-label text-muted fs-6">Nie wymagaj zgodności adresów IP</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" tabindex="-1" id="modalIps" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
              <div class="modal-header">
                <h2 v-if="edit.ips_mode == 'edit'" class="fw-bolder">Adresy IP</h2>
                <h2 v-else-if="edit.ips_mode == 'add'" class="fw-bolder">Dodaj adres IP</h2>
                <div class="btn btn-icon btn-sm btn-active-icon-primary btn-close" data-bs-dismiss="modal" aria-label="Close" ref="ModalIpsClose">
                  <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                    </svg>
                  </span>
                </div>
              </div>
              <div v-if="edit.ips_mode == 'edit'" class="modal-body scroll-y mx-5 mx-xl-15 my-7 p-0">

                <div v-if="app.ips">
                  <div v-for="app_ip in app.ips">
                    <div v-if="!app_ip.delete" class="d-flex flex-wrap align-items-center">
                      <div>
                        <div class="fs-6 fw-bolder mb-1">{{ app_ip.ip }}</div>
                      </div>
                      <div class="ms-auto mb-4">
                        <button class="btn btn-secondary btn-sm" @click="app_ip.delete = true">Usuń</button>
                      </div>
                      <div class="separator separator-dashed my-6"></div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  Brak adresów IP
                </div>
              </div>

              <div v-else-if="edit.ips_mode == 'add'" class="modal-body scroll-y mx-5 mx-xl-15 my-7 p-0">
                <div class="container">
                  <input type="text" :class="{ 'is-invalid': errors.new_ip }" @focus="errors.new_ip = false" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="x.x.x.x" v-model="new_ip" />
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.new_ip }}</div>
                </div>
              </div>

              <div v-if="edit.ips_mode == 'edit'" class="w-100 d-flex col-lg-6 justify-content-end p-6 card-footer">
                  <button @click="reload()" data-bs-dismiss="modal" aria-label="Close" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
                  <button @click="saveIps()" data-bs-dismiss="modal" class="btn btn-primary">Zaakceptuj</button>
              </div>

              <div v-if="edit.ips_mode == 'add'" class="w-100 d-flex col-lg-6 justify-content-end p-6 card-footer">
                  <button @click="new_ip = ''; reload()" data-bs-dismiss="modal" aria-label="Close" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
                  <button @click="addIP" class="btn btn-primary">Zaakceptuj</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" tabindex="-1" id="modalScopes" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="fw-bolder">Uprawnienia</h2>
                <div class="btn btn-icon btn-sm btn-active-icon-primary btn-close" data-bs-dismiss="modal" aria-label="Close">
                  <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                    </svg>
                  </span>
                </div>
              </div>
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <div v-for="(scope, index) in app.org_scopes">
                  <label class="form-check form-check-custom form-check-solid align-items-start">
                    <input class="form-check-input me-3" :value="scope.name" type="checkbox" v-model="app._scopes" />
                    <span class="form-check-label d-flex flex-column align-items-start">
                      <span class="fs-5">{{ scope.description }}</span>
                    </span>
                  </label>
                  <div class="separator separator-dashed my-6"></div>
                </div>
                <div class="d-flex flex-wrap align-items-center text-muted">
                    Zmiana uprawnień dotyczy nowo generowanych tokenów.
                </div>
                <div class="card-footer d-flex justify-content-end pt-6 pb-0 px-9 mt-15">
                  <button @click="reload()" class="btn btn-white btn-active-light-primary me-2" data-bs-dismiss="modal">Anuluj</button>
                  <button @click="saveScopes" class="btn btn-primary" data-bs-dismiss="modal">Zapisz zmiany</button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="card mb-5 mb-xl-10" v-if="app.internal_client">
          <div class="card-header border-0 cursor-pointer" role="button">
            <div class="card-title m-0">
              <h3 class="fw-bolder m-0">Adresy przekierowania</h3>
            </div>
            <button type="button" @click="edit.redirects_mode = 'edit'" class="btn btn-primary align-self-center" data-bs-toggle="modal" data-bs-target="#modalRedirects">Edytuj</button>
          </div>
          <div class="card-body border-top pt-3 fs-6">
            <ul class="mt-4">
              <li v-for="redirect in redirects">{{ redirect.url }}</li>
            </ul>
          </div>
          <div class="card-body b-0 p-9 pt-0">
            <div class="mt-4"><a @click="edit.redirects_mode = 'add'" class="link-primary fs-5 me-1 cursor-pointer" data-bs-toggle="modal" data-bs-target="#modalRedirects" data-bs-whatever="@modalRedirects">Dodaj adres przekierowania</a></div>
            <div class="separator separator-dashed my-6 mt-3"></div>
            <div class="d-flex flex-wrap align-items-center">
              <div>
                <div class="text-muted">Funkcjonalność jest używana tylko dla Authorization Code Flow (logowania użytkownika do systemu RWDZ).</div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" tabindex="-1" id="modalRedirects" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
              <div class="modal-header">
                <h2 v-if="edit.redirects_mode == 'edit'" class="fw-bolder">Adresy przekierowań</h2>
                <h2 v-else-if="edit.redirects_mode == 'add'" class="fw-bolder">Dodaj adres przekierowania</h2>
                <div class="btn btn-icon btn-sm btn-active-icon-primary btn-close" data-bs-dismiss="modal" aria-label="Close" ref="ModalRedirectsClose">
                  <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                    </svg>
                  </span>
                </div>
              </div>
              <div v-if="edit.redirects_mode == 'edit'" class="modal-body scroll-y mx-5 mx-xl-15 my-7 p-0">

                <div v-for="redirect in redirects">
                  <div v-if="!redirect.delete" class="d-flex flex-wrap align-items-center">
                    <div>
                      <div class="fs-6 mb-1">{{ redirect.url }}</div>
                    </div>
                    <div class="ms-auto mb-4">
                      <button class="btn btn-secondary btn-sm" @click="redirect.delete = true">Usuń</button>
                    </div>
                    <div class="separator separator-dashed my-6"></div>
                  </div>
                </div>

              </div>

              <div v-else-if="edit.redirects_mode == 'add'" class="modal-body scroll-y mx-5 mx-xl-15 my-7 p-0">
                <div class="container">
                  <input type="text" :class="{ 'is-invalid': errors.new_redirect }" @focus="errors.new_redirect = false" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="https://" v-model="new_redirect" />
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.new_redirect }}</div>
                </div>
              </div>

              <div v-if="edit.redirects_mode == 'edit'" class="w-100 d-flex col-lg-6 justify-content-end p-6 card-footer">
                  <button @click="reload()" data-bs-dismiss="modal" aria-label="Close" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
                  <button @click="saveRedirects()" data-bs-dismiss="modal" class="btn btn-primary">Zaakceptuj</button>
              </div>

              <div v-if="edit.redirects_mode == 'add'" class="w-100 d-flex col-lg-6 justify-content-end p-6 card-footer">
                  <button @click="new_redirect = ''; reload()" data-bs-dismiss="modal" aria-label="Close" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
                  <button @click="addRedirect" class="btn btn-primary">Zaakceptuj</button>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-5 mb-xl-10" v-if="app.internal_client">
          <div class="card-header border-0 cursor-pointer" role="button">
            <div class="card-title m-0">
              <h3 class="fw-bolder m-0">Klucz publiczny</h3>
            </div>
            <a v-if="edit.pubkey == false" @click="startEditionPubkey" class="btn btn-primary align-self-center">Edytuj</a>
          </div>
          <div class="collapse show">
            <div v-if="edit.pubkey" class="card-body border-top p-9">
              <textarea :class="{ 'is-invalid': errors.pubkey }" @change="errors.pubkey = false" class="form-control" rows="10" v-model="app.pubkey" />
              <div class="fv-plugins-message-container invalid-feedback">{{ errors.pubkey }}</div>
              <div class="d-flex justify-content-end py-6 px-9 mt-5">
                <button @click="edit.pubkey = false; reload()" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
                <button @click="savePubkey" class="btn btn-primary">Zapisz zmiany</button>
              </div>
              <div class="separator separator-dashed my-6"></div>
              <div class="d-flex flex-wrap align-items-center">
                <div>
                  <p>Utwórz klucz prywatny (wariant 1 - z hasłem do klucza):<br>
                  <samp>
                    openssl genrsa -des3 -out private.key 2048
                  </samp>
                  </p>
                  <p>Utwórz klucz prywatny (wariant 2 - bez hasła do klucza):<br>
                  <samp>
                    openssl genrsa -out private.key 2048
                  </samp>
                  </p>
                  <p>Utwórz klucz publiczny:<br>
                  <samp>
                    openssl rsa -in private.key -outform PEM -pubout -out public.key
                  </samp>
                  </p>
                  <p>Skopiuj zawartość public.key, wklej do formularza i zapisz. Plik klucza prywatnego private.key użyj w kliencie API.</p>
                </div>
              </div>
            </div>
            <div v-else class="card-body border-top p-9">
              <div class="d-flex flex-wrap align-items-center">
                <div>
                  <pre v-if="app.pubkey" class="fs-6">{{ app.pubkey }}</pre>
                  <div v-else class="fs-6 mb-10">Nie ustawiono klucza publicznego.</div>
                </div>
              </div>
              <label class="form-check form-switch form-check-custom form-check-solid mt-5">
      					<input @click="togglePubkeyCheck" class="form-check-input w-30px h-20px" type="checkbox" :checked="app.pubkey_check">
      					<span v-if="app.pubkey_check" class="form-check-label text-muted fs-6">Klucz jest wymagany dla środowiska produkcyjnego</span>
      					<span v-else class="form-check-label text-muted fs-6">Klucz nie jest wymagany dla środowiska produkcyjnego</span>
      				</label>
              <div class="separator separator-dashed my-6"></div>
              <div class="d-flex flex-wrap align-items-center">
                <div>
                  <div v-if="app.pubkey_check" class="text-muted">Klucz nie jest wymagany dla środowiska sandbox.</div>
                  <div v-else class="text-muted">Klucz nie jest wymagany dla środowiska produkcyjnego oraz sandbox.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="page=='stats'">

        <div class="card mb-5 mb-xl-10">
          <div class="card-header cursor-pointer">
            <div class="card-title m-0">
              <h3 class="fw-bolder m-0">Limity zapytań</h3>
            </div>
            <a v-if="edit.limits == false && adminMode" @click="startEditionLimits" class="btn btn-primary align-self-center">Edytuj</a>
          </div>
          <div v-if="edit.limits == false" class="card-body p-9">
            <div class="row mb-7">
              <label class="col-lg-4 fw-bold text-muted">Limit dzienny</label>
              <div class="col-lg-8">
                <span class="fs-6 text-dark">{{ app.daily_limit }}</span>
              </div>
            </div>
            <div class="row mb-7">
              <label class="col-lg-4 fw-bold text-muted">Limit godzinowy</label>
              <div class="col-lg-8 d-flex align-items-center">
                <span class="text-justify fs-6">{{ app.hourly_limit }}</span>
              </div>
            </div>
          </div>
          <div v-else-if="adminMode" class="collapse show">
            <div class="card-body border-top p-9">
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Dzienny limit</label>
                <div class="col-lg-8 fv-row">
                  <input type="text" :class="{ 'is-invalid': errors.daily_limit }" @change="errors.daily_limit = false" class="form-control form-control-lg form-control-solid" v-model="app.daily_limit" />
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.daily_limit }}</div>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Limit godzinowy</label>
                <div class="col-lg-8 fv-row">
                  <input type="text" :class="{ 'is-invalid': errors.hourly_limit }" @change="errors.hourly_limit = false" class="form-control form-control-lg form-control-solid" v-model="app.hourly_limit" />
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.hourly_limit }}</div>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button @click="edit.limits = false; reload()" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
              <button @click="saveLimits" class="btn btn-primary">Zapisz zmiany</button>
            </div>
          </div>
        </div>

        <div class="card mb-5 mb-xl-10">
          <div class="card-header cursor-pointer">
            <div class="card-title m-0">
              <h3 class="fw-bolder m-0">Statystyki dzienne</h3>
            </div>
          </div>
          <div class="card-body border-top p-9">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div v-for="stat in statsDailyCols.col1" :class="stat.counter == 0 ? 'text-muted' : ''">
                  <pre class="col-6 fs-6 d-inline">{{ stat.date }}: </pre>
                  <span :class="stat.counter > 0 ? 'fw-bolder' : ''" class="col-6 fs-6">{{ stat.counter }}</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div v-for="stat in statsDailyCols.col2" :class="stat.counter == 0 ? 'text-muted' : ''">
                  <pre class="col-6 fs-6 d-inline">{{ stat.date }}: </pre>
                  <span :class="stat.counter > 0 ? 'fw-bolder' : ''" class="col-6 fs-6">{{ stat.counter }}</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div v-for="stat in statsDailyCols.col3" :class="stat.counter == 0 ? 'text-muted' : ''">
                  <pre class="col-6 fs-6 d-inline">{{ stat.date }}: </pre>
                  <span :class="stat.counter > 0 ? 'fw-bolder' : ''" class="col-6 fs-6">{{ stat.counter }}</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div v-for="stat in statsDailyCols.col4" :class="stat.counter == 0 ? 'text-muted' : ''">
                  <pre class="col-6 fs-6 d-inline">{{ stat.date }}: </pre>
                  <span :class="stat.counter > 0 ? 'fw-bolder' : ''" class="col-6 fs-6">{{ stat.counter }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-5 mb-xl-10">
          <div class="card-header cursor-pointer">
            <div class="card-title m-0">
              <h3 class="fw-bolder m-0">Statystyki godzinowe</h3>
            </div>
          </div>
          <div class="card-body border-top p-9">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div v-for="stat in statsHourlyCols.col1" :class="stat.counter == 0 ? 'text-muted' : ''">
                  <pre class="col-6 fs-6 d-inline">{{ stat.date }}: </pre>
                  <span :class="stat.counter > 0 ? 'fw-bolder' : ''" class="col-6 fs-6">{{ stat.counter }}</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div v-for="stat in statsHourlyCols.col2" :class="stat.counter == 0 ? 'text-muted' : ''">
                  <pre class="col-6 fs-6 d-inline">{{ stat.date }}: </pre>
                  <span :class="stat.counter > 0 ? 'fw-bolder' : ''" class="col-6 fs-6">{{ stat.counter }}</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div v-for="stat in statsHourlyCols.col3" :class="stat.counter == 0 ? 'text-muted' : ''">
                  <pre class="col-6 fs-6 d-inline">{{ stat.date }}: </pre>
                  <span :class="stat.counter > 0 ? 'fw-bolder' : ''" class="col-6 fs-6">{{ stat.counter }}</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div v-for="stat in statsHourlyCols.col4" :class="stat.counter == 0 ? 'text-muted' : ''">
                  <pre class="col-6 fs-6 d-inline">{{ stat.date }}: </pre>
                  <span :class="stat.counter > 0 ? 'fw-bolder' : ''" class="col-6 fs-6">{{ stat.counter }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-else="deletion_stage == 1" class="card mb-5 mb-xl-10">
      <!--begin::Card header-->
      <div class="card-header border-0 cursor-pointer" role="button">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0 text-danger">Usuwanie aplikacji</h3>
        </div>
      </div>
        <div class="card-body border-top p-9">
          <div class="d-flex flex-wrap align-items-center">
            <div>
              <div class="fs-6 fw-bolder mb-1">Potwierdź usunięcie aplikacji {{ app.name }}</div>
              <div class="fw-bold text-gray-600">Jeśli chcesz usunąć aplikację, kliknij przycisk. Operacji nie można cofnąć.</div>
            </div>
          </div>
        </div>
        <div class="card-footer border-top p-9">
          <div class="float-start">
            <button class="btn btn-secondary" @click="deletion_stage = 0">Anuluj usuwanie</button>
          </div>
          <div class="float-end">
            <button class="btn btn-danger" @click="deleteAction">Potwierdź usunięcie</button>
          </div>
        </div>
    </div>

    <div class="float-start ms-auto mb-15">
      <button v-if="deletion_stage == 0" class="btn btn-white btn-sm" v-bind:class="{ 'btn-danger': deleteOver }" @mouseover="deleteOver=true" @mouseleave="deleteOver=false" @click="deletion_stage = 1">Usuń aplikację</button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'AppsDetails',
  inject: ['$profile', '$organisation'],
  props: ['id', 'appid'],
  data() {
    return {
      app: {},
      page: 'main',
      timer: false,
      statsTimer: false,
      edit: {
        'basics': false,
        'ips': false,
        'redirects': false,
        'pubkey': false,
        'scopes': false,
        'limits': false
      },
      statsDaily: [],
      statsHourly: [],
      statsDailyCols: {'col1': [], 'col2': [], 'col3': [], 'col4': []},
      statsHourlyCols: {'col1': [], 'col2': [], 'col3': [], 'col4': []},
      errors: {},
      adminMode: false,
      showAppKey: false,
      resetOver: false,
      new_ip: '',
      redirects: [],
      new_redirect: '',
      deletion_stage: 0,
      deleteOver: false
    }
  },
  /*
  beforeRouteUpdate(to, from, next) {
    this.reload();
    next();
  },*/
  created () {
      this.reload();
      this.timer = setInterval(this.reload, 60000);
  },
  mounted() {
    this.$bus.on('updateAdminMode', (value) => { // here you need to use the arrow function
     this.adminMode = value;
    });
    this.adminMode = this.$root.$refs.topbar.adminMode;
  },
  methods: {
    saveBasics: function() {
      var self = this;
      this.$root.$refs.api.patch('organisations/' + self.id + '/apps/' + self.appid, {
        'name': self.app.name,
        'description': self.app.description,
        'sandbox_only': self.app.sandbox_only
      }).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.app = response.data;
          self.edit.basics = false;
          self.errors.name = false;
          self.errors.description = false;
        }
      })
      .catch(function (error) {
          self.errors = error.response.data;
      });
    },
    savePubkey: function() {
      var self = this;
      this.$root.$refs.api.patch('organisations/' + self.id + '/apps/' + self.appid, {
        'pubkey': self.app.pubkey
      }).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.app = response.data;
          self.edit.pubkey = false;
          self.errors.pubkey = false;
        }
      })
      .catch(function (error) {
          self.errors = error.response.data;
      });
    },
    saveScopes: function() {
      var self = this;
      this.$root.$refs.api.patch('organisations/' + self.id + '/apps/' + self.appid, {
        'scopes': self.app._scopes
      }).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.app = response.data;
          self.edit.scopes = false;
          self.errors.scopes = false;
        }
      })
      .catch(function (error) {
          self.errors = error.response.data;
      });
    },
    saveIps: function() {
      var self = this;
      var ips = [];
      self.app.ips.forEach(function(item) {
        if (item.delete) return;
        ips.push(item.ip)
      });
      this.$root.$refs.api.patch('organisations/' + self.id + '/apps/' + self.appid, {
        'ips': ips
      }).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.app = response.data;
          self.$refs.ModalIpsClose.click();
        }
      })
      .catch(function (error) {
          if (typeof error.response != 'undefined' && typeof error.response.data != 'undefined')
          {
            self.errors = error.response.data;
          }
      });
    },
    saveRedirects: function() {
      var self = this;
      var redirects = [];
      self.redirects.forEach(function(item) {
        if (item.delete) return;
        redirects.push(item.url)
      });
      this.$root.$refs.api.patch('organisations/' + self.id + '/apps/' + self.appid, {
        'redirects': redirects
      }).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.app = response.data;
          if (self.app.redirects && Array.isArray(self.app.redirects))
          {
            self.redirects = [];
            self.app.redirects.forEach(function(item){
              self.redirects.push({'url': item, 'delete': false});
            });
          }
          self.$refs.ModalRedirectsClose.click();
        }
      })
      .catch(function (error) {
          if (typeof error.response != 'undefined' && typeof error.response.data != 'undefined')
          {
            self.errors = error.response.data;
          }
      });
    },
    saveLimits: function() {
      var self = this;
      this.$root.$refs.api.patch('organisations/' + self.id + '/apps/' + self.appid, {
        'daily_limit': self.app.daily_limit,
        'hourly_limit': self.app.hourly_limit
      }).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.app = response.data;
          self.edit.limits = false;
          self.errors.daily_limit = false;
          self.errors.hourly_limit = false;
        }
      })
      .catch(function (error) {
          self.errors = error.response.data;
      });
    },
    startEditionBasics: function() {
      this.errors.name = false;
      this.errors.description = false;
      this.edit.basics = true;
      return false;
    },
    startEditionPubkey: function() {
      this.errors.pubkey = false;
      this.edit.pubkey = true;
      return false;
    },
    startEditionScopes: function() {
      var self = this;
      this.app._scopes = [];
      this.app.scopes.forEach(function(scope) {
        self.app._scopes.push(scope.name);
      });
      this.errors.scopes = false;
      this.edit.scopes = true;
      return false;
    },
    startEditionLimits: function() {
      this.errors = {};
      this.edit.limits = true;
      return false;
    },
    toggleActivity() {
      var self = this;
      this.$root.$refs.api.patch('organisations/' + self.id + '/apps/' + self.appid, {
        'active': !this.app.active
      }).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.app = response.data;
        }
      })
      .catch(function () {
      });
    },
    toggleIpRestrictions() {
      var self = this;
      this.$root.$refs.api.patch('organisations/' + self.id + '/apps/' + self.appid, {
        'ip_restrictions': !this.app.ip_restrictions
      }).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.app = response.data;
        }
      })
      .catch(function () {
      });
    },
    togglePubkeyCheck() {
      var self = this;
      this.$root.$refs.api.patch('organisations/' + self.id + '/apps/' + self.appid, {
        'pubkey_check': !this.app.pubkey_check
      }).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.app = response.data;
        }
      })
      .catch(function () {
      });
    },
    resetKey() {
      if (!this.resetOver) return;
      var self = this;
      this.$root.$refs.api.get('organisations/' + self.id + '/apps/' + self.appid + '/reset')
      .then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.app = response.data;
        }
      })
      .catch(function () {
      });
    },
    reload: function() {
      var self = this;
      for (var key in this.edit) {
          if (this.edit[key]) return;
      }
      this.$root.$refs.api.get('organisations/' + self.id + '/apps/' + self.appid).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.app = response.data;
          if (self.app.redirects && Array.isArray(self.app.redirects))
          {
            self.redirects = [];
            self.app.redirects.forEach(function(item){
              self.redirects.push({'url': item, 'delete': false});
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    reloadStats: function() {
      var self = this;
      this.$root.$refs.api.get('organisations/' + self.id + '/apps/' + self.appid + '/stats').then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          if (typeof response.data.daily !== 'undefined')
          {
            self.statsDaily = response.data.daily;
            self.statsDailyCols = {'col1': [], 'col2': [], 'col3': [], 'col4': []};
            let part1 = Math.floor(self.statsDaily.length / 4);
            let part2 = Math.floor(self.statsDaily.length / 4 * 2);
            let part3 = Math.floor(self.statsDaily.length / 4 * 3);
            for (let i = 0; i < self.statsDaily.length; i++)
            {
                if (i <= part1)
                {
                  self.statsDailyCols['col1'].push(self.statsDaily[i]);
                }
                else if (i <= part2)
                {
                  self.statsDailyCols['col2'].push(self.statsDaily[i]);
                }
                else if (i <= part3)
                {
                  self.statsDailyCols['col3'].push(self.statsDaily[i]);
                }
                else
                {
                  self.statsDailyCols['col4'].push(self.statsDaily[i]);
                }
            }
          }
          else
          {
            self.statsDaily = [];
            self.statsDailyCols = {'col1': [], 'col2': [], 'col3': [], 'col4': []};
          }
          if (typeof response.data.hourly !== 'undefined')
          {
            self.statsHourly = response.data.hourly;
            self.statsHourlyCols = {'col1': [], 'col2': [], 'col3': [], 'col4': []};
            let part1 = Math.floor(self.statsHourly.length / 4);
            let part2 = Math.floor(self.statsHourly.length / 4) * 2;
            let part3 = Math.floor(self.statsHourly.length / 4) * 3;
            for (let i = 0; i < self.statsHourly.length; i++)
            {
                if (i <= part1)
                {
                  self.statsHourlyCols['col1'].push(self.statsHourly[i]);
                }
                else if (i <= part2)
                {
                  self.statsHourlyCols['col2'].push(self.statsHourly[i]);
                }
                else if (i <= part3)
                {
                  self.statsHourlyCols['col3'].push(self.statsHourly[i]);
                }
                else
                {
                  self.statsHourlyCols['col4'].push(self.statsHourly[i]);
                }
            }
          }
          else
          {
            self.statsHourly = [];
            self.statsDailyCols = {'col1': [], 'col2': [], 'col3': [], 'col4': []};
            self.statsHourlyCols = {'col1': [], 'col2': [], 'col3': [], 'col4': []};
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        self.statsDaily = [];
        self.statsHourly = [];
        self.statsHourlyCols = {'col1': [], 'col2': [], 'col3': [], 'col4': []};
      });
    },
    addIP: function() {
      if (this.new_ip && this.new_ip.match(/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/))
      {
        this.app.ips.push({'ip': this.new_ip});
        this.new_ip = '';
        this.saveIps();
      }
      else
      {
        this.errors = {'new_ip': "Nieprawidłowy adres IP"};
      }
    },
    addRedirect: function() {
      if (this.new_redirect && this.new_redirect.match(/^https?:\/\/[a-zA-Z0-9:/.?&=-]+$/))
      {
        this.redirects.push({'url': this.new_redirect, 'delete': false});
        this.new_redirect = '';
        this.saveRedirects();
      }
      else
      {
        this.errors = {'new_redirect': "Nieprawidłowy adres przekierowania"};
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
      clearInterval(this.statsTimer);
    },
    copyAppId() {
      this.copyToClipboard(this.app.id);
    },
    copyAppKey() {
      this.copyToClipboard(this.app.key);
    },
    copyToClipboard(textToCopy) {
      if (navigator.clipboard && window.isSecureContext) {
          return navigator.clipboard.writeText(textToCopy);
      } else {
          let textArea = document.createElement("textarea");
          textArea.value = textToCopy;
          textArea.style.position = "fixed";
          textArea.style.left = "-999999px";
          textArea.style.top = "-999999px";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          return new Promise((res, rej) => {
              document.execCommand('copy') ? res() : rej();
              textArea.remove();
          });
      }
    },
    deleteAction: function() {
      var self = this;
      var id = false;
      if (this.id)
      {
        id = this.id;
      }
      else if (this.organisation && this.organisation.uuid)
      {
        id = this.organisation.uuid;
      }
      else
      {
        return;
      }
      this.$root.$refs.api.delete('organisations/' + id + '/apps/' + this.app.id).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.$router.push('../apps');
        }
      })
      .catch(function (error) {
          console.log(error);
      });
    },
    computed: {
      organisation() {
        return this.$organisation();
      },
      profile() {
        return this.$profile();
      }
    }
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
    this.$bus.off('updateAdminMode');
  },
  watch: {
    page: function(val) {
      if (val == 'stats')
      {
        this.reloadStats();
        this.statsTimer = setInterval(this.reloadStats, 10000);
      }
      else
      {
        clearInterval(this.statsTimer);
      }
    }
  }
};
</script>

<style>
ul.scopes {
  list-style-type: none;
  padding: 0;
}
ul.scopes li {
  padding: 0;
}
</style>
